import React from 'react'
import { BsArrowRight } from "react-icons/bs";
import service1 from "../assets/rulasackesim.jpg"
import service2 from "../assets/ruladilmekesim.jpg"
import service3 from "../assets/sacbukum.jpeg"
import service4 from "../assets/sackesim.jpg"
import { Link } from 'react-router-dom';

const ServicesHome = () => {
  return (
    <div className='text-white lg:px-24 px-4'>
        <div className='h-36'>
        </div>    

        {/*services sections */}
        <div className='lg:flex lg:justify-between items-start'>
            {/*content*/}
            <div className='lg:w-1/3 space-y-5'>
                <h2 className='text-3xl font-bold mb-5 leading-snug'>Profesyonellikle Şekillendiriyoruz, Güvenle Teslim Ediyoruz.</h2>
                <Link to="/services" className='inline-flex items-center gap-2 font-semibold hover:text-primary transition-all duration-300 ease-in'>
                    <span>Hizmetlerimizi Git</span>
                    <BsArrowRight/>
                </Link>
            </div>

            {/*cards*/}
            <div className='lg:w-2/3 my-8'>
                    <div className='flex flex-wrap'>
                        <div className='lg:w-1/4 hidden lg:block'></div>
                        <div className='service-card'>
                            <Link to="/servicesfeatures/12" className='rounded-2xl block relative'>
                                <img src={service1} alt="" className='rounded-2xl'/>
                                <div className='absolute bg-black left-0 top-0 opacity-50 h-full w-full rounded-2xl'></div>
                                {/*text*/}
                                <div className='absolute top-1/3 left-4 right-0'>
                                    <span className='w-3 h-3 block rounded-full bg-reds mb-2'>
                                    </span>
                                    <h2 className='text-2xl font-bold text-white'>Rulo Sac Boy Kesme</h2>
                                </div>
                            </Link>
                        </div>
                        <div className='lg:w-1/4 hidden lg:block'></div>
                        <div className='service-card'>
                            <Link to="/servicesfeatures/1" className='rounded-2xl block relative'>
                                <img src={service2} alt="" className='rounded-2xl'/>
                                <div className='absolute bg-black left-0 top-0 opacity-50 h-full w-full rounded-2xl'></div>
                                {/*text*/}
                                <div className='absolute top-1/3 left-4 right-0'>
                                    <span className='w-3 h-3 block rounded-full bg-reds mb-2'>
                                    </span>
                                    <h2 className='text-2xl font-bold text-white'>Rulo Sac Dilme</h2>
                                </div>
                            </Link>
                        </div>
                        <div className='service-card'>
                            <Link to="/servicesfeatures/124" className='rounded-2xl block relative'>
                                <img src={service3} alt="" className='rounded-2xl'/>
                                <div className='absolute bg-black left-0 top-0 opacity-50 h-full w-full rounded-2xl'></div>
                                {/*text*/}
                                <div className='absolute top-1/3 left-4 right-0'>
                                    <span className='w-3 h-3 block rounded-full bg-reds mb-2'>
                                    </span>
                                    <h2 className='text-2xl font-bold text-white'>Levha Sac Bükümü</h2>
                                </div>
                            </Link>
                        </div>
                        <div className='service-card'>
                            <Link to="/servicesfeatures/112" className='rounded-2xl block relative'>
                                <img src={service4} alt="" className='rounded-2xl'/>
                                <div className='absolute bg-black left-0 top-0 opacity-50 h-full w-full rounded-2xl'></div>
                                {/*text*/}
                                <div className='absolute top-1/3 left-4 right-0'>
                                    <span className='w-3 h-3 block rounded-full bg-reds mb-2'>
                                    </span>
                                    <h2 className='text-2xl font-bold text-white'>Levha Sac Kesim</h2>
                                </div>
                            </Link>
                        </div>
                        
                    </div>
            </div>
        </div>
    </div>
  )
}

export default ServicesHome