import React from 'react';

const ServiceFeatureTable = ({ features }) => {
  return (
    <div className="overflow-x-auto lg:p-20 md:p-12 p-2">
    <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
      <thead className="ltr:text-left rtl:text-right">
        <tr>
          {features.map(feature => (
            <th key={feature.name} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">{feature.name}</th>
          ))}
        </tr>
      </thead>
  
      <tbody className="divide-y divide-gray-200 text-center">
        {features[0].value.split(',').map((_, index) => (
          <tr key={index}>
            {features.map(feature => ( 
              <td key={feature.name} className="whitespace-nowrap px-4 py-2 text-gray-700">{feature.value.split(',')[index]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  );
};

export default ServiceFeatureTable;