import Hero from '../components/Hero'
import AboutHome from '../components/AboutHome'
import ServicesHome from '../components/ServicesHome'
import BlogsHome from '../components/BlogsHome'
import bgimage from "../assets/bg.jpg";
const Home = () => {
  return (
    <div>
      <Hero pageTitle="Metal Dünyasında Güvenilir  Çözüm Ortaklığı" backgroundImage={bgimage} />
      <AboutHome/>
      <BlogsHome/>
      <ServicesHome/>
    </div>
  )
}

export default Home