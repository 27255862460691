import React from 'react'
import { FaPhone } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import bametalLogo from "../assets/bametal_logo.png";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
   <div>
        <div className="bg-white h-1/2 w-full flex md:flex-row flex-col justify-around items-start p-20 mt-auto">
				<div className="p-5 ">
					<ul>
                    <img src={bametalLogo} alt="Logo" className="h-20 w-auto pb-6" />
						<div className="flex gap-6 pb-5">
							<Link to="https://www.instagram.com/bametalsanayi/">
							<FaInstagram className="text-2xl cursor-pointer hover:text-yellow-600" />
							</Link>
							
							<FaTwitter className="text-2xl cursor-pointer hover:text-blue-600" />
							<FaLinkedin className="text-2xl cursor-pointer hover:text-blue-600" />
							<FaYoutube className="text-2xl cursor-pointer hover:text-red-600" />
						</div>
					</ul>
				</div>
				<div className="p-5">
					<ul>
						<p className="text-bls font-bold text-2xl pb-4">Misyon</p>
						<li className="text-gray-500 text-md pb-2 font-semibold">
						Kalite
						</li>
						<li className="text-gray-500 text-md pb-2 font-semibold">
						İşçilik
						</li>
						<li className="text-gray-500 text-md pb-2 font-semibol">
						Proje Teslim
						</li>
						<li className="text-gray-500 text-md pb-2 font-semibold ">
						Güven
						</li>
					</ul>
				</div>
				<div className="p-5">
					<ul>
						<p className="text-bls font-bold text-2xl pb-4">Şirket</p>
						<li className="text-gray-500 text-md pb-2 font-semibold hover:text-reds cursor-pointer">
							<Link to="/aboutus">Hakkımızda</Link>
						</li>
						<li className="text-gray-500 text-md pb-2 font-semibold hover:text-reds cursor-pointer">
							<Link to="/products">Ürünler</Link>
						</li>
						<li className="text-gray-500 text-md pb-2 font-semibold hover:text-reds cursor-pointer">
							<Link to="/services">Hizmetler</Link>
						</li>
						<li className="text-gray-500 text-md pb-2 font-semibold hover:text-reds cursor-pointer">
							<Link to="/catalog">E-Katalog</Link>	
						</li>
					</ul>
				</div>
				<div className="p-5">
					<ul>
						<p className="text-bls font-bold text-2xl pb-4">Iletişim</p>
						<li class="text-gray-500 text-md pb-2 font-semibold hover:text-reds cursor-pointer flex items-center">
                            <span><FaPhone class="inline-block"/> +90 (546) 587 94 44</span>
                        </li>
						<li class="text-gray-500 text-md pb-2 font-semibold hover:text-reds cursor-pointer flex items-center">
                            <span><IoMailSharp class="inline-block"/> bilgi@bametalsanayi.com </span>
                        </li>
						<li className="text-gray-500 text-md pb-2 font-semibold ">
						Akat Mah. Akasya Sk. No: 3 Beşiktaş/İSTANBUL, 34335
						</li>
					</ul>
				</div>
			</div>
			<div className="flex flex-col justify-center items-center text-center  p-5 text-primary bg-white">
				<h1 className=" ">
				© 2024 All rights reserved | BAMETAL METAL SANAYİ TİC. LTD. ŞTİ.	
				</h1>
			</div>
			<div className="flex flex-col justify-center items-center text-center  p-5 bg-primary">
				<h1 className=" text-white font-extralight">
					<Link to="https://www.kutluayulutas.com/">
					Tasarım | Kutluay Ulutas
					</Link>
				</h1>
			</div>


   </div>
  )
}

export default Footer