export const links = [
    {
      name: "Ürünlerimiz",
      submenu: true,
      url: "products",
      sublinks: [
        {
          Head: "SICAK HADDELENMİŞ RULO / PAKET SAC",
          link:"productfeatures/1"
        },
        {
          Head: "SICAK HADDELENMİŞ LEVHA SAC",
          link:"productfeatures/2"
        },
        {
          Head: "SOĞUK HADDELENMİŞ (DKP) RULO / PAKET SAC",
          link:"productfeatures/3"
        },
        {
          Head: "HRP RULO / PAKET SAC (ASİTLENMİŞ YAĞLANMIŞ SAC)",
          link:"productfeatures/4"
        },
        {
          Head: "GALVANİZLİ RULO / PAKET SAC",
          link:"productfeatures/5"
        },
        {
          Head: "BOYALI SAC",
          link:"productfeatures/6"
        },
        {
          Head: "BAKLAVALI / GÖZYAŞI DESENLİ SAC",
          link:"productfeatures/7"
        },
        {
          Head: "TRAPEZ / OLUKLU SAC",
          link:"productfeatures/8"
        },
        {
          Head: "GENİŞLETİLMİŞ / DELİKLİ SAC",
          link:"productfeatures/9"
        },

      ],
    },
    {
      name: "Hizmetlerimiz",
      submenu: true,
      url: "services",
      sublinks: [
        {
          Head: "RULO SAC DİLME",
          link:"servicesfeatures/1"
        },
        {
          Head: "RULO SAC BOY KESME",
          link:"servicesfeatures/12"
        },
        {
          Head: "LEVHA SAC KESME",
          link:"servicesfeatures/112"
        },
  
        {
          Head: "LEVHA SAC BÜKÜM",
          link:"servicesfeatures/124"
        },
      ],
    },
  ];