import React from 'react'
import HeroPage from '../components/HeroPage'
import AboutUsComp from '../components/AboutUsComp'
import AboutUsComp2 from '../components/AboutUsComp2'
import bgimage from "../assets/bg.jpg";

const AboutUs = () => {
  return (
    <div>
        <HeroPage pageTitle="HAKKIMIZDA" backgroundImage={bgimage} />
        <AboutUsComp/>
        <AboutUsComp2/>
    </div>
  )
}

export default AboutUs