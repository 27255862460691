import React from 'react'


const ServicesComp = () => {
  return (
    <section class="bg-primary">
      <div class="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 md:py-16 lg:px-8">
        <div class="mx-auto max-w-3xl text-center">
          <h2 class="text-3xl font-bold text-white sm:text-4xl">HİZMET KALİTESİNİN ÖNEMİ</h2>

          <p class="mt-4 text-gray-500 sm:text-xl">
          Metal sac hizmeti, çeşitli endüstrilerde kullanılan metal levhaların kesilmesi, bükülmesi, delinmesi ve şekillendirilmesi
           gibi işlemleri kapsar. Metal sacın doğru şekilde işlenmesi, ürünlerin kalitesini artırır, maliyetleri azaltır ve üretim sürecini optimize eder.
           Bu nedenle, metal sac hizmeti endüstrinin verimliliği ve rekabetçiliği açısından büyük öneme sahiptir.
          </p>
        </div>

        <div class="mt-8 sm:mt-12">
          <dl class="grid grid-cols-1 gap-4 sm:grid-cols-1">
            <div class="flex flex-col rounded-lg bg-gray-700 px-4 py-8 text-center">
            <div className="space-y-4">
                  <details className="group [&_summary::-webkit-details-marker]:hidden" open>
                    <summary
                      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-primary p-4 text-white"
                    >
                      <h2 className="font-medium ">RULO SAC DİLME</h2>

                      <svg
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-180 "
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </summary>

                    <p className="mt-4 px-4 leading-relaxed text-white">
                      Metal rulo saclarının belirli genişliklere dilimlenmesi işlemidir.
                      Genellikle rulo saclarının belirli ölçülerde kesilmesi veya daha küçük rulo sacların elde edilmesi için yapılır.
                      Bu işlem genellikle rulo sac levhaların istenilen genişliğe getirilmesi amacıyla gerçekleştirilir.
                    </p>
                  </details>

                  <details className="group [&_summary::-webkit-details-marker]:hidden">
                    <summary
                      className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-primary p-4 text-white"
                    >
                      <h2 className="font-medium ">RULO SAC BOY KESME</h2>

                      <svg
                        className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </summary>

                    <p className="mt-4 px-4 leading-relaxed text-white">
                     Metal rulo saclarının belirli bir uzunlukta kesilmesi işlemidir.
                     Bu işlem genellikle müşteri taleplerine veya üretim gereksinimlerine bağlı olarak yapılır. Örneğin, bir müşterinin belirli boyutlarda
                     metal levha talebi olabilir veya üretim sürecinde belirli uzunluklarda parçaların hazırlanması gerekebilir.
                    </p>
                  </details>
            </div>
            </div>
            <div class="flex flex-col rounded-lg bg-gray-700 px-4 py-8 text-center">
            <div className="space-y-4">
              <details className="group [&_summary::-webkit-details-marker]:hidden" open>
                <summary
                  className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-primary text-white p-4 "
                >
                  <h2 className="font-medium text-white">LEVHA SAC KESME</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </summary>

                <p className="mt-4 px-4 leading-relaxed text-white">
                Metal levha saclarının belirli ölçülere kesilmesi işlemidir. Kesme işlemi genellikle levha sacların belirli boyutlara getirilmesi, müşteri taleplerine göre kesilmesi
                 veya belirli parçaların üretilmesi amacıyla yapılır.
                  Bu işlem, metal levha sacların istenilen şekil ve boyutlara getirilmesini
                   sağlar.
                </p>
              </details>

              <details className="group [&_summary::-webkit-details-marker]:hidden">
                <summary
                  className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-primary p-4 text-white"
                >
                  <h2 className="font-medium">LEVHA SAC BÜKÜM</h2>

                  <svg
                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </summary>

                <p className="mt-4 px-4 leading-relaxed text-white">
                Metal levha saclarının belirli şekillerde bükülmesi veya katlanması işlemidir. Bu işlem genellikle levha sacların belirli parçaların üretilmesi veya belirli geometrik şekillerin elde edilmesi amacıyla yapılır. Örneğin, metal levha saçların
                 bükülerek çeşitli metal parçaların imalatında kullanılması gibi.
                  Bu işlem, metal levha sacların belirli bir şekil veya yapıya getirilmesini sağlar.
                </p>
              </details>
            </div>
            </div>
          </dl>
        </div>
      </div>
    </section>
  )
}

export default ServicesComp