import React, { useState } from "react";
import { Link,useLocation } from "react-router-dom";
import { links } from "./MyLinks";
import { SlArrowDown } from "react-icons/sl";

const NavLinks = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  const location = useLocation();

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      {links.map((link) => (
        <div key={link.name}>
          <div className="px-3 text-left md:cursor-pointer group ">
          <div className="flex items-center justify-between">
            <Link to={`/${link.url}`} className={`flex items-center hover:text-reds hover:underline ${isActive(`/${link.url}`) ? 'text-reds underline' : ''}`}>
                <h1
                    className="md:py-7 py-4 flex justify-between items-center md:pr-0 pr-5 group  "
                >
                    {link.name}
                </h1>
                <span className="text-sm md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-1 ">
                    <SlArrowDown name="chevron-down" className="h-5"></SlArrowDown>
                </span>
            </Link>
            <span className="text-xl md:hidden inline">
                <SlArrowDown onClick={() => {
                        heading !== link.name ? setHeading(link.name) : setHeading("");
                        setSubHeading("");
                    }}
                    name={`${
                        heading === link.name ? "chevron-up" : "chevron-down"
                    }`}
                ></SlArrowDown>
            </span>
        </div>
            
            {link.submenu && (
              <div>
                <div className="absolute top-20 hidden group-hover:md:block hover:md:block">
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 absolute
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white p-5 grid grid-cols-3 gap-6">
                    {link.sublinks.map((mysublinks) => (
                      <div key={mysublinks.Head}>
                        <h1 className="text-md hover:text-reds">
                          <Link to={`/${mysublinks.link}`}>{mysublinks.Head}</Link>
                        </h1>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks) => (
              <div key={slinks.Head}>
                <div>
                  <h1
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                    }
                    className="py-2 pl-7 font-semibold md:pr-0 pr-5 flex justify-between items-center md:pr-0 pr-5"
                  >
                    <Link to={`${slinks.link}`}>{slinks.Head}</Link>
                  </h1>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;