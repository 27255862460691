import { useState } from 'react';
import { postData } from "../api/Api";
import Swal from 'sweetalert2';
import { ImSpinner8 } from "react-icons/im";
const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    phone: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false); 

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const notifySuccess = () => {
    Swal.fire({
      icon: 'success',
      title: 'Başarılı!',
      text: 'Form başarıyla gönderildi.',
    });
  };

  const notifyError = () => {
    Swal.fire({
      icon: 'error',
      title: 'Hata!',
      text: 'Form gönderilirken bir hata oluştu.',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsLoading(true); 
      try {
        const response = await postData('Send.php', formData); 
        console.log('Response:', response);
        notifySuccess(); 
        setFormData({
          name: '',
          lastname: '',
          phone: '',
          email: '',
          message: ''
        });
      } catch (error) {
        notifyError(); 
        setIsLoading(false);
      } finally {
        setIsLoading(false); 
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    
    if (formData.name.trim() === '') {
      newErrors.name = 'İsim alanı zorunludur.';
    }
    if (formData.lastname.trim() === '') {
      newErrors.lastname = 'Soyisim alanı zorunludur.';
    }
    if (formData.phone.trim() === '') {
      newErrors.phone = 'Telefon alanı zorunludur.';
    }
    if (formData.email.trim() === '') {
      newErrors.email = 'Email alanı zorunludur.';
    }
    if (formData.message.trim() === '') {
      newErrors.message = 'Mesaj alanı zorunludur.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <section className="text-gray-400 bg-gray-900 body-font relative">
      <div className="container px-5 py-24 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="lg:w-2/3 md:w-1/2 bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
          <iframe
            title="map"
            className="absolute inset-0"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.1330963653218!2d29.022102200000003!3d41.08793849999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab67348c2ec6f%3A0x6de3bb0749ca7854!2zQWthdCwgQWthc3lhIFNrLiBObzozLCAzNDMzNSBCZcWfaWt0YcWfL8Swc3RhbmJ1bA!5e0!3m2!1str!2str!4v1709735998127!5m2!1str!2str"
          ></iframe>
          <div className="bg-gray-900 relative flex flex-wrap py-6 rounded shadow-md">
            <div className="lg:w-1/2 px-6">
              <h2 className="title-font font-semibold text-white tracking-widest text-xs">ADRES</h2>
              <p className="mt-1">Akat Mah. Akasya Sk. No: 3 Beşiktaş/İSTANBUL, 34335</p>
            </div>
            <div className="lg:w-1/2 px-6 mt-4 lg:mt-0">
              <h2 className="title-font font-semibold text-white tracking-widest text-xs">EMAIL</h2>
              <a href="mailto:example@email.com" className="text-indigo-400 leading-relaxed">bilgi@bametalsanayi.com</a>
              <h2 className="title-font font-semibold text-white tracking-widest text-xs mt-4">TELEFON</h2>
              <p className="leading-relaxed">+90 (546) 587 94 44</p>
            </div>
          </div>
        </div>
        <div className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
          <h2 className="text-white text-lg mb-1 font-medium title-font">İletişim Formu</h2>
          <p className="leading-relaxed mb-5">Size nasıl yardımcı olabiliriz? İletişim formumuzu doldurun, sorularınızı veya geri bildirimlerinizi bize iletmenizden memnuniyet duyarız. En kısa sürede size geri dönüş yapacağız.</p>
          <form onSubmit={handleSubmit}>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-400">İsim</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${errors.name && 'border-red-500'}`}
                required
                aria-describedby="name-error"
              />
              {errors.name && <p id="name-error" className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>
            <div className="relative mb-4">
              <label htmlFor="lastname" className="leading-7 text-sm text-gray-400">Soy İsim</label>
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className={`w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${errors.lastname && 'border-red-500'}`}
                required
                aria-describedby="lastname-error"
              />
              {errors.lastname && <p id="lastname-error" className="text-red-500 text-xs mt-1">{errors.lastname}</p>}
            </div>
            <div className="relative mb-4">
              <label htmlFor="phone" className="leading-7 text-sm text-gray-400">Telefon</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className={`w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${errors.phone && 'border-red-500'}`}
                required
                aria-describedby="phone-error"
              />
              {errors.phone && <p id="phone-error" className="text-red-500 text-xs mt-1">{errors.phone}</p>}
            </div>
            <div className="relative mb-4">
              <label htmlFor="email" className="leading-7 text-sm text-gray-400">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out ${errors.email && 'border-red-500'}`}
                required
                aria-describedby="email-error"
              />
              {errors.email && <p id="email-error" className="text-red-500 text-xs mt-1">{errors.email}</p>}
            </div>
            <div className="relative mb-4">
              <label htmlFor="message" className="leading-7 text-sm text-gray-400">Mesaj</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                className={`w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out ${errors.message && 'border-red-500'}`}
                required
                aria-describedby="message-error"
              ></textarea>
              {errors.message && <p id="message-error" className="text-red-500 text-xs mt-1">{errors.message}</p>}
            </div>
            <button type="submit" className="text-white bg-indigo-500 border-0 py-2 px-12 focus:outline-none hover:bg-indigo-600 rounded text-lg relative">
              {isLoading && <ImSpinner8 className="animate-spin mr-2" />}
              {isLoading ? "" : "Gönder"}
            </button>
          </form>
          <p className="text-xs text-gray-400 text-opacity-90 mt-3">BAMETAL METAL SANAYİ TİC. LTD. ŞTİ.</p>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;