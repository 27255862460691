import React from 'react';

const AboutUsComp2 = () => {
  return (
    <div className="flex flex-col lg:flex-row justify-center items-center lg:justify-between lg:items-start p-24 ">
      <div className="w-full lg:w-1/2 lg:pr-8  lg:text-left pt-2">
        <h2 className="text-2xl lg:text-3xl font-bold mb-4 text-white">Misyonumuz</h2>
        <p className="text-gray-400 text-xs sm:text-sm lg:text-xl">
        Misyonumuz, müşterilerimize mükemmel hizmet sunarak onların ihtiyaçlarını en iyi şekilde karşılamak ve güvenilir bir tedarikçi olarak sektörde öncü konumumuzu korumaktır.
         Kaliteyi ön planda tutarak, müşteri memnuniyetini her zaman en üst seviyede tutmayı hedefliyoruz.
         Ayrıca, çevresel ve toplumsal sorumluluklarımızı da yerine getirerek sürdürülebilir bir gelecek için çalışıyoruz.
        </p>
      </div>
      <div className="w-full lg:w-1/2 lg:pl-8 lg:text-left mt-8 lg:mt-0 lg:border-l-8 border-t-8 lg:border-t-0 pt-2">
        <h2 className="text-2xl lg:text-3xl font-bold mb-4 text-white">Vizyonumuz</h2>
        <p className="text-gray-400 text-xs sm:text-sm lg:text-xl">
        Vizyonumuz, metal sektöründe lider bir marka olmak ve müşterilerimizin güvenini kazanarak uzun vadeli iş ilişkileri kurmaktır.
         Sürekli olarak yenilikçi çözümler geliştirerek sektördeki trendleri belirlemeyi ve yönlendirmeyi amaçlıyoruz.
          Müşterilerimize en iyi kalitede ürünleri en hızlı şekilde sunarak, sektörde öncü bir konuma gelmeyi hedefliyoruz.
        </p>
      </div>
    </div>
  );
}

export default AboutUsComp2;