import React, { useState, useEffect } from "react";
import { Link,useLocation } from "react-router-dom";
import Logo from "../assets/bametal_logo.png";
import NavLinks from "./NavLinks";
import { CiMenuFries } from "react-icons/ci";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation(); 
  const location = useLocation();

  useEffect(() => {
    setOpen(false); 
  }, [pathname]); 

  const toggleMenu = () => setOpen(!open);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <header className="navbar-wrapper fixed top-0 w-full bg-white border-primary border-b-2">
      <nav className="bg-white ">
        <div className="flex items-center font-medium justify-around">
          <div className="z-50 p-5 md:w-auto w-full flex justify-between">
            <Link to="/">
              <img src={Logo} alt="logo" className="md:cursor-pointer h-8" />
            </Link>

            <div className="text-3xl md:hidden" onClick={toggleMenu}>
              <CiMenuFries name={`${open ? "close" : "menu"}`} />
            </div>
          </div>
          <ul className="md:flex hidden items-center gap-2 font-[Poppins]">
            <li>
              <Link to="/aboutus" className={`md:py-2 py-4 px-3 inline-block hover:text-reds hover:underline ${isActive('/aboutus') ? 'text-reds underline' : ''}`}>
                Hakkımızda
              </Link>
            </li>
            <NavLinks />
            <li>
              <Link to="/catalog" className={`md:py-2 py-4 px-3 inline-block hover:text-reds hover:underline ${isActive('/catalog') ? 'text-reds underline' : ''}`}>
                E-katalog
              </Link>
            </li>
            <li>
              <Link to="/contact" className={`md:py-2 py-4 px-3 inline-block hover:text-reds hover:underline ${isActive('/contact') ? 'text-reds underline' : ''}`}>
                İletişim
              </Link>
            </li>
          </ul>
        </div>

        {/* Mobile nav */}
        <ul
          className={`
            md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-2
            duration-500 ${open ? "left-0" : "left-[-100%]"}
          `}
        >
          <li>
            <Link to="/aboutus" className={`md:py-2 py-4 px-3 inline-block hover:text-reds hover:underline ${isActive('/aboutus') ? 'text-reds underline' : ''}`}>
              Hakkımızda
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link to="/catalog" className={`md:py-2 py-4 px-3 inline-block hover:text-reds hover:underline ${isActive('/catalog') ? 'text-reds underline' : ''}`}>
              E-Katalog
            </Link>
          </li>
          <li>
            <Link to="/contact" className={`md:py-2 py-4 px-3 inline-block hover:text-reds hover:underline ${isActive('/contact') ? 'text-reds underline' : ''}`}>
              İletişim
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Navbar;