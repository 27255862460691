import React from 'react'
import { useParams } from 'react-router-dom';
import ServicesData from '../ServicesData';
import HeroPage2 from '../components/HeroPage2';
import bgimage from "../assets/bg.jpg";
import ServicesDetails from '../components/ServicesDetails';
import ServiceComp2 from '../components/ServiceComp2';

const ServicesFeatures = () => {

  const { id } = useParams();
  const service = ServicesData.find(service => service.id === parseInt(id));  


  return (
    <div className='bg-white'>
         <HeroPage2 backgroundImage={bgimage} pageTitle={service.name}/>
         <ServicesDetails services={service}/>
         <ServiceComp2 ServicesData={ServicesData}/>
    </div>
  )
}

export default ServicesFeatures