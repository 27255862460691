import services1 from "./assets/services1.jpg";
import services2 from "./assets/services2.jpg";
import services3 from "./assets/services3.jpg";
import services4 from "./assets/services4.jpeg";
const ServicesData = [
    {
        id: 1,
        name: "RULO SAC DİLME",
        description:"Metal rulo saclarının belirli genişliklere dilimlenmesi işlemidir. Genellikle rulo saclarının belirli ölçülerde kesilmesi veya daha küçük rulo sacların elde edilmesi için yapılır. Bu işlem genellikle rulo sac levhaların istenilen genişliğe getirilmesi amacıyla gerçekleştirilir.",
        image: services1,
        features: [
            { name: "Malzeme Kalınlığı (mm)", value: "0.5,1.0,2.0,3.0" },
            { name: "Malzeme Genişliği (mm)", value: "1000,1500,2000,2500" },
            { name: "Kesim Hızı (m/dak)	", value: "20,15,10,8" },
            { name: "Kesim Uzunluğu (mm)", value: "5000,6000,7000,8000" },
          ]
    },
    {
        "id": 12,
        "name": "RULO SAC BOY KESME",
        "description": "Metal rulo saclarının belirli bir uzunlukta kesilmesi işlemidir. Bu işlem genellikle müşteri taleplerine veya üretim gereksinimlerine bağlı olarak yapılır. Örneğin, bir müşterinin belirli boyutlarda metal levha talebi olabilir veya üretim sürecinde belirli uzunluklarda parçaların hazırlanması gerekebilir.",
        "image": services2,
        "features": [
          {
            "name": "Malzeme Kalınlığı (mm)",
            "value": "0.5, 1.0, 2.0, 3.0"
          },
          {
            "name": "Malzeme Genişliği (mm)",
            "value": "1000, 1500, 2000, 2500"
          },
          {
            "name": "Kesim Uzunluğu (mm)",
            "value": "5000, 6000, 7000, 8000"
          },
          {
            "name": "Kesim Hızı (m/dak)",
            "value": "20, 15, 10, 8"
          },
          {
            "name": "Kesim Hassasiyeti (mm)",
            "value": "0.1, 0.2, 0.3, 0.5"
          }
        ]
      },
      {
        "id": 112,
        "name": "LEVHA SAC KESME",
        "description": "Metal levha saclarının belirli ölçülere kesilmesi işlemidir. Kesme işlemi genellikle levha sacların belirli boyutlara getirilmesi, müşteri taleplerine göre kesilmesi veya belirli parçaların üretilmesi amacıyla yapılır. Bu işlem, metal levha sacların istenilen şekil ve boyutlara getirilmesini sağlar.",
        "image": services3,
        "features": [
          {
            "name": "Malzeme Kalınlığı (mm)",
            "value": "0.5, 1.0, 2.0, 3.0"
          },
          {
            "name": "Malzeme Genişliği (mm)",
            "value": "1000, 1500, 2000, 2500"
          },
          {
            "name": "Kesim Uzunluğu (mm)",
            "value": "5000, 6000, 7000, 8000"
          },
          {
            "name": "Kesim Hızı (m/dak)",
            "value": "20, 15, 10, 8"
          },
          {
            "name": "Kesim Hassasiyeti (mm)",
            "value": "0.1, 0.2, 0.3, 0.5"
          }
        ]
      },
      {
        "id": 124,
        "name": "LEVHA SAC BÜKÜM",
        "description": "Metal levha saclarının belirli şekillerde bükülmesi veya katlanması işlemidir. Bu işlem genellikle levha sacların belirli parçaların üretilmesi veya belirli geometrik şekillerin elde edilmesi amacıyla yapılır. Örneğin, metal levha saçların bükülerek çeşitli metal parçaların imalatında kullanılması gibi. Bu işlem, metal levha sacların belirli bir şekil veya yapıya getirilmesini sağlar.",
        "image": services4,
        "features": [
          {
            "name": "Malzeme Kalınlığı (mm)",
            "value": "0.5, 1.0, 2.0, 3.0"
          },
          {
            "name": "Malzeme Genişliği (mm)",
            "value": "1000, 1500, 2000, 2500"
          },
          {
            "name": "Büküm Uzunluğu (mm)",
            "value": "500, 600, 700, 800"
          },
          {
            "name": "Bükme Hızı (derece/dak)",
            "value": "30, 25, 20, 15"
          },
          {
            "name": "Büküm Hassasiyeti (derece)",
            "value": "0.1, 0.2, 0.3, 0.5"
          }
        ]
      }
];


export default ServicesData;