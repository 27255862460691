import React from 'react';
import { BsArrowRight } from "react-icons/bs";
import res1 from "../assets/paketlenmissac.jpg";
import res2 from "../assets/TRAPEZSAC.jpg";
import res3 from "../assets/siyahsac.jpg";
import res4 from "../assets/deliklisac.jpg";
import { Link } from 'react-router-dom';

const features = [
    { name: 'Çeşitli Kalınlıklar', description: 'Saclar farklı kalınlıklarda üretilebilir, bu da çeşitli uygulamalara uygunluk sağlar.' },
    { name: 'Mukavemet', description: 'Sac malzemeler genellikle yüksek mukavemet özelliklerine sahiptir, bu da yapısal dayanıklılıklarını artırır.' },
    { name: 'Şekillendirilebilirlik', description: 'Soğuk haddelenmiş saclar özellikle kolayca şekillendirilebilir, böylece çeşitli tasarım gereksinimlerini karşılamak için kullanılabilirler.' },
    { name: 'Yüzey Pürüzlülüğü', description: 'Sac malzemelerin yüzeyi genellikle düzgün ve pürüzsüzdür, bu da boyama ve kaplama işlemlerini kolaylaştırır.' },
  ]

const BlogsHome = () => {


  return (
    <div className="bg-primary md:mt-20 mt-10">
        <div className="mx-auto grid max-w-2xl grid-cols-1 items-center gap-x-8 gap-y-16 px-4 py-24 sm:px-6 sm:py-32 lg:max-w-7xl lg:grid-cols-2 lg:px-8">
        <div>
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Özellikler</h2>
            <p className="mt-4 text-gray-500">
            Metal saclar, genellikle metal levha veya rulolarından üretilen ince,
             düz ve geniş yüzeyli malzemelerdir. Bu saclar, genellikle çeşitli metal alaşımlarından,
              özellikle çelik, alüminyum ve paslanmaz çelikten üretilirler.
             Metal saclar, çeşitli endüstriyel, yapısal, dekoratif ve ambalaj uygulamalarında kullanılır.
            </p>

            <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
                <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-white">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                </div>
            ))}
            </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
            <img
            src={res1}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="rounded-lg bg-gray-100"
            />
            <img
            src={res2}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="rounded-lg bg-gray-100"
            />
            <img
            src={res3}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="rounded-lg bg-gray-100"
            />
            <img
            src={res4}
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="rounded-lg bg-gray-100"
            />
        </div>
        <div className="flex justify-center ">
            <Link to="/products" className='inline-flex items-center gap-2 text-3xl font-semibold hover:text-red-500 transition-all duration-300 ease-in text-white text-center shadow-lg rounded-lg p-2'>
            <span>DAHA FAZLASINI GÖR</span><BsArrowRight/></Link>
        </div>
        </div>
       
  </div>
  );
};

export default BlogsHome;