import React from 'react';
import { Link } from 'react-router-dom'; // Link component'i için gerekli import

const ServiceComp2 = ({ ServicesData }) => {

  return (
    <div className='grid xl:grid-cols-4 grid-cols-2 bg-primary mb-20 md:p-20 p-2 mt-6'>
      {ServicesData.map((service, index) => (
        <div key={index} className='m-2'>
          <Link to={`/servicesfeatures/${service.id}`} className="group relative block bg-black"> {/* Link component'i kullanılıyor */}
            <img
              alt=""
              src={service.image} 
              className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50 "
            />

            <div className="relative p-4 sm:p-6 lg:p-8">
              <p className="text-sm font-medium uppercase tracking-widest text-bls"><span className='text-reds'>ba</span>metal</p> 
              <p className="text-xl font-bold text-white sm:text-2xl">{service.name}</p> 

              <div className="mt-32 sm:mt-48 lg:mt-64">
                <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                  <p className="text-sm text-white">Hizmete Git</p> 
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default ServiceComp2;