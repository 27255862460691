import React from 'react'
import { BsBoxFill } from "react-icons/bs";
const ProductComp2 = () => {
  return (
    <section className="bg-gray-900 text-white md:p-16">
  <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 mx-auto">
    <div className="max-w-xl ">
      <h2 className="text-3xl font-bold sm:text-4xl">Metal Sacların İşlenmesi</h2>

      <p className="mt-4 text-gray-300">
      Metal sacların haddelenmesi, metal levhaların haddehane tesislerinde ısıtılarak, soğutularak ve sıkıştırılarak kalınlığının azaltılması veya şekillendirilmesi işlemidir. Galvaniz kaplanması ise, çelik sacların paslanmayı önlemek için çinko ile kaplanması işlemidir. Bu işlem, çelik sacların çinko kaplanarak korozyona karşı dayanıklılığını artırır.
       Sonuç olarak, galvaniz kaplama saclar daha uzun ömürlü olur ve çeşitli endüstrilerde kullanılan metal ürünlerin
        dayanıklılığını artırır.
      </p>
    </div>

    <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-1 md:gap-12 lg:grid-cols-3">
      <div className="flex items-start gap-4 md:mt-10">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
        <BsBoxFill className="h-6 w-6 text-gray-600" />
        </span>

        <div className='gap-4'>
          <h2 className="text-lg font-bold">1. Sıcak Haddelenmiş Saclar</h2>

          <p className="mt-1 text-sm text-gray-300">
          - Otomotiv Endüstrisi: Otomobil gövde parçaları, paneller, şasi bileşenleri.<br/>
          - İnşaat: Çatı kaplamaları, duvar panelleri, döşeme malzemeleri.<br/>
          - Beyaz Eşya: Buzdolapları, çamaşır makineleri, fırınlar gibi ev aletleri.<br/>
          - Metal Mobilya: Ofis mobilyaları, mutfak tezgahları, dolaplar.
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4 md:mt-10">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
        <BsBoxFill className="h-6 w-6 text-gray-600" />
        </span>

        <div>
          <h2 className="text-lg font-bold">2. Soğuk Haddelenmiş Saclar</h2>

          <p className="mt-1 text-sm text-gray-300">
          - Otomotiv Endüstrisi: Kapı panelleri, tavan panelleri, egzoz sistemleri.<br/>
          - Elektronik: Telefon kasanları, bilgisayar kasaları, televizyon gövdeleri.<br/>
          - Ambalaj Endüstrisi: Teneke kutular, konserve ambalajları, kapaklar.<br/>
          - Ev Aletleri: Fırınlar, mikrodalga fırınlar, su ısıtıcıları.
          </p>
        </div>
      </div>

      <div className="flex items-start gap-4 md:mt-10">
        <span className="shrink-0 rounded-lg bg-gray-800 p-4">
        <BsBoxFill className="h-6 w-6 text-gray-600" />
        </span>

        <div>
          <h2 className="text-lg font-bold">3. Galvanizli Saclar.</h2>

          <p className="mt-1  text-sm text-gray-300">
          - İnşaat: Çatı kaplamaları, çit panelleri, boru malzemeleri.<br/>
          - Tarım Endüstrisi: Depo yapıları, seralar, sulama sistemleri.<br/>
          - Altyapı Projeleri: Korkuluklar, köprüler, demiryolu rayları.<br/>
          - Ev Aletleri: Su deposu, ısıtma tesisatı, havalandırma kanalları.
          </p>
        </div>
      </div>

      
    </div>
  </div>
    </section>
  )
}

export default ProductComp2