import React from 'react';
import { Link } from 'react-router-dom';
import ProductsData from '../data';
import { BsBoxFill } from "react-icons/bs";

const ProductList = () => {
  return (
    <section className='bg-white'>
        <hr className=" border-2 border-gray-300 rounded-full w-3/4 mx-auto" /> {/* Adding styled horizontal line */}
      <div className="max-w-screen-xl mx-auto px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
        <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:items-center lg:gap-x-16 mx-auto"> {/* Adding mx-auto class to center the grid */}
          <div className="mx-auto max-w-lg text-center lg:mx-0 ltr:lg:text-left rtl:lg:text-right">
            <h2 className="text-3xl font-bold sm:text-4xl">Ürünlerimiz </h2>

            <p className="mt-4 text-gray-600">
            Metal saclar genellikle çelikten veya alüminyumdan yapılan düz levhalardır.
             Endüstriyel kullanımlarda geniş bir yelpazede kullanılmaktadırlar.
              İnşaat sektöründe çatı ve duvar kaplamalarında, otomotiv endüstrisinde araç gövdelerinde,
               beyaz eşya üretiminde ve birçok başka alanlarda kullanılırlar. Çelik saclar genellikle dayanıklı,
             mukavemetli ve kolay işlenebilir özelliklere sahiptirler.
              Alüminyum saclar ise daha hafif ve korozyona dayanıklıdır.
            </p>

            <Link
              to="/contact"
              className="mt-8 inline-block rounded bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
            >
              Bizimle İletişime Geçebilirsin
            </Link>
          </div>

          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 mx-auto"> {/* Adding mx-auto class to center the inner grid */}
            {ProductsData.map((product, index) => (
              <Link key={index} to={`/productfeatures/${product.id}`} className="block rounded-xl border border-gray-100 p-4 shadow-sm hover:border-gray-200 hover:ring-1 hover:ring-gray-200 focus:outline-none focus:ring">
                <span className="inline-block rounded-lg bg-gray-50 p-3">
                  <BsBoxFill className="h-6 w-6 text-gray-600" /> {/* Using Accountant icon from react-icons */}
                </span>

                <h2 className="mt-2 font-bold">{product.title}</h2>

               
              </Link>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductList;