import React from 'react';

const HeroPage = ({ pageTitle, backgroundImage }) => {
  const heroStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className='hero-about-us relative' style={heroStyle}>
    <div className="absolute inset-0 bg-black opacity-40"></div>
    <div className="absolute inset-0 flex  items-center justify-center">
      <h1 className="sm:text-5xl text-3xl text-white text-center">{pageTitle}</h1>
    </div>
  </div>
  );
}

export default HeroPage;