import React from 'react'
import HeroPage from '../components/HeroPage'
import bgimage from "../assets/bg.jpg";
import ServicesComp from '../components/ServicesComp';
import ServiceComp2 from '../components/ServiceComp2';
import ServicesData from '../ServicesData';
import bametalLogo from "../assets/bametal_logo.png";

const Services = () => {
  return (
    <div className='bg-white'>
      <HeroPage pageTitle="HIZMETLER" backgroundImage={bgimage} />
      <hr className=" border-2 border-reds rounded-full w-3/4 mx-auto mt-8" /> 
      <div class="flex justify-center">
        <img src={bametalLogo} alt="Logo" className="h-20 w-auto pb-6 mt-4 items-center" />
      </div>
      <hr className=" border-2 border-bls rounded-full w-3/4 mx-auto" />
      <ServiceComp2 ServicesData={ServicesData}/>
      <ServicesComp />
      
    </div>
  )
}

export default Services