import axios from 'axios';

const BASE_URL = 'https://bametalsanayi.com/backend'; 


export const postData = async (endpoint, data) => {
  try {
    const response = await axios.post(`${BASE_URL}/${endpoint}`, data);
    return response.data; 
  } catch (error) {
    console.error('There was a problem with the request:', error.message);
    throw error;
  }
};