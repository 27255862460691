import React from 'react'
import HeroPage from '../components/HeroPage'
import bgimage from "../assets/bg.jpg";
import CatalogComp from '../components/CatalogComp';

const Catalog = () => {
  return (
    <div>
        <HeroPage pageTitle="E-KATALOG" backgroundImage={bgimage}/>
        <CatalogComp/>
    </div>
  )
}

export default Catalog