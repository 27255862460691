import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Home from './page/Home';
import Products from './page/Products';
import Services from './page/Services';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AboutUs from './page/AboutUs';
import Contact from './page/Contact';
import Catalog from './page/Catalog';
import ProductFeatures from './page/ProductFeatures';
import ServicesFeatures from './page/ServicesFeatures';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/productfeatures/:id" element={<ProductFeatures />} />
        <Route path="/services" element={<Services />} />
        <Route path="/servicesfeatures/:id" element={<ServicesFeatures />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/catalog" element={<Catalog />} />
      </Routes>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default App;