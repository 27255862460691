import React from 'react';
import { GoArrowRight } from "react-icons/go";
import { Link } from 'react-router-dom';

const Hero = ({ pageTitle, backgroundImage }) => {
  const heroStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <div className='hero-home relative' style={heroStyle}>
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="absolute inset-0 flex flex-col items-center justify-center">
        <h1 className="text-5xl text-white mb-8 text-center">{pageTitle}</h1>
        <Link to="/services" className="gap-2 p-4 text-white flex items-center justify-center text-3xl  hover:text-red-500 transition-all duration-300 ease-in">
          <span>Hizmetlerimizi Gör</span>
          <GoArrowRight className="w-6 h-6 " />
        </Link>
      </div>
    </div>
  );
}

export default Hero;