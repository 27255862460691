import React from 'react';

const ProductFeaturesType = ({ features }) => {
  // Kontrol etmek için kullanılacak olan özellik isimleri
  const specialFeatureNames = ['Kalınlık (mm)'];

const areSpecialFeatures = (features) => {
  return specialFeatureNames.every(feature => features.some(item => item.name === feature));
}

console.log(areSpecialFeatures(features)); // true
  

  const data = {
    features: [
      {"name": "Kalınlık (mm)", "value": "2.50, 2.50, 2.50, 3.00, 3.00, 3.00, 4.00, 4.00, 4.00, 6.00, 6.00, 6.00"},
      {"name": "En (mm)", "value": "1000, 1200, 1500, 1000, 1200, 1500, 1000, 1250, 1500, 1000, 1200, 1500"},
      {"name": "Boy (mm)", "value": "2000, 2500, 2500, 2000, 2500, 2500, 2500, 2500, 2500, 2000, 2500, 2500"},
      {"name": "Ağırlık (kg/m)", "value": "40, 60, 75, 48, 72, 90, 96, 100, 120, 96, 144, 180"}
    ]
  };
  
  console.log(data);

  // Eğer tüm özellikler istenilen özelliklerse, farklı bir tablo yapısı döndür
  if (!areSpecialFeatures(features)) {
    return ( 
        
        <div className="mt-2 flex justify-center items-center flex-col p-4 lg:p-8">
        {/* Orijinal */}
        <h2 className="text-xl font-bold sm:text-2xl">Özellikler</h2>
        <table className="mt-4">
          <tbody>
            {features.map((feature, index) => (
              <tr key={index} className='border-b-2'>
                <td className="font-semibold pr-4">{feature.name}</td>
                <td>{feature.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  else { // Değilse, farklı bir tablo yapısı döndür
    return (
        <div className="overflow-x-auto">
        <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr>
              {features.map(feature => (
                <th key={feature.name} className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">{feature.name}</th>
              ))}
            </tr>
          </thead>
      
          <tbody className="divide-y divide-gray-200 text-center">
            {features[0].value.split(',').map((_, index) => (
              <tr key={index}>
                {features.map(feature => ( // 'data.features' yerine 'features' kullanıldı
                  <td key={feature.name} className="whitespace-nowrap px-4 py-2 text-gray-700">{feature.value.split(',')[index]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
};

export default ProductFeaturesType;