import React from 'react';
import { GoArrowRight } from "react-icons/go";
import { Link } from 'react-router-dom';

const ProductsComp = ({ products }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-4 md:p-24 p-4 bg-white">
      {products.map((product, index) => (
        <article key={index} className="overflow-hidden shadow transition hover:shadow-xl cursor-pointer hover:brightness-110">
          <Link to={`/productfeatures/${product.id}`} className="flex flex-col h-full">
            <img
              alt={product.title}
              src={product.image}
              className="h-56 w-full object-cover"
            />
            <div className="bg-white p-4 sm:p-6 flex flex-col justify-between flex-grow">
              <div>
                <time dateTime={product.date} className="block text-xs text-gray-500">{product.date}</time>
                <h3 className="mt-0.5 text-lg text-gray-900">{product.title}</h3>
                <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
                  {product.description}
                </p>
              </div>
              <div className="flex items-center justify-start mt-4">
                <span className="text-reds text-xl mr-2">Detayları Gör</span>
                <GoArrowRight className="w-6 h-6 text-red-500" />
              </div>
            </div>
          </Link>
        </article>
      ))}
    </div>
  );
}

export default ProductsComp;