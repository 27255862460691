import React from 'react';
import ServiceFeatureTable from './ServiceFeatureTable';

const ServicesDetails = ({ services }) => {
  return (
    <div className="mt-2">
      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 p-16">
        {/* Sol tarafta resim */}
        <div className="md:h-96 h-64 rounded-lg bg-gray-200">
          <img
            src={services.image} 
            alt={services.title} 
            className="h-full w-full object-cover rounded-lg"
          />
        </div>
        <div className="flex flex-col justify-center">
          <h2 className="text-3xl font-semibold mb-2">{services.name}</h2>
          <p className="text-gray-600 md:text-lg text-sm">{services.description}</p>
        </div>
      </div>
      {/*service tablom burada */}
      <ServiceFeatureTable features={services.features}/>
    </div>
  );
};

export default ServicesDetails;