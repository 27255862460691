import React from 'react'
import { FaAward } from "react-icons/fa";
import { VscWorkspaceTrusted } from "react-icons/vsc";
import { GiAutoRepair } from "react-icons/gi";
import { GrServices } from "react-icons/gr";
import { BsBoxes } from "react-icons/bs";
import { MdEngineering } from "react-icons/md";

const AboutHome = () => {
  return (
    <div className='text-white md:-mt-[300px] -mt-24 bg-gradient-to-tr from-[#161616] to-[#080808] lg:mx-24 md:py-20 mx-4 py-12 md:px-16 px-6 rounded-xl z-50 flex flex-col md:flex-row justify-between
     md:items-center gap-12 relative'>
        <div className='bg-[url(../src/assets/funfact_bg.svg)] bg-no-repeat bg-center h-full w-full absolute top-0 left-0 opacity-75'>

        </div>
        {/*left side text */}
        <div className='md:w-2/5'>
             <div class="flex items-center">
                <h2 class="text-4xl lg:text-5xl font-bold mb-5">Metal İhtiyaçlarınızı Doğru ve Hızlı Şekilde Karşılıyoruz</h2>
            </div>
            <p>Geniş ürün yelpazemizde yer alan çeşitli
                 metal türleri ile, müşterilerimizin farklı 
                ihtiyaçlarını karşılayabilmekteyiz. Ayrıca, özel talepleriniz doğrultusunda özelleştirilmiş çözümler de sunmaktayız.
            </p>
        </div>
        {/*right side text */}
        <div className='md:w-3/5'>
            <div className='grid sm:grid-cols-2 grid-cols-1 gap-8'>
                <div className='flex h-auto items-center gap-3'>
                    <h4 className='md:text-5xl text-3xl font-semibold'><FaAward/></h4>
                    <div>
                        <p className='mb-2 text-sm leading-tight text-gray-300 '>KALITE</p>
                    </div>
                </div>
                <div className='flex h-auto items-center gap-3'>
                    <h4 className='md:text-5xl text-3xl font-semibold'><VscWorkspaceTrusted /></h4>
                    <div>
                        <p className='mb-2 text-sm leading-tight text-gray-300 '>GUVEN</p>
                    </div>
                </div>
                <div className='flex h-auto items-center gap-3'>
                    <h4 className='md:text-5xl text-3xl font-semibold'><GiAutoRepair /></h4>
                    <div>
                        <p className='mb-2 text-sm leading-tight text-gray-300 '>İŞÇİLİK</p>
                    </div>
                </div>
                <div className='flex h-auto items-center gap-3'>
                    <h4 className='md:text-5xl text-3xl font-semibold'><GrServices/></h4>
                    <div>
                        <p className='mb-2 text-sm leading-tight text-gray-300 '>ÜSTÜN HİZMET</p>
                    </div>
                </div>
                <div className='flex h-auto items-center gap-3'>
                    <h4 className='md:text-5xl text-3xl font-semibold'><MdEngineering /></h4>
                    <div>
                        <p className='mb-2 text-sm leading-tight text-gray-300 '>PROJE TESLIM</p>
                    </div>
                </div>
                <div className='flex h-auto items-center gap-3'>
                    <h4 className='md:text-5xl text-3xl font-semibold'><BsBoxes /></h4>
                    <div>
                        <p className='mb-2 text-sm leading-tight text-gray-300 '>ÜRÜN STOK</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutHome