import bametalLogo from "../assets/bametal_logo.png";

const AboutUsComp = () => {
  return (
    <div className="bg-gray-100 py-20">
      <div className="container mx-auto flex flex-col lg:flex-row">
        {/* Resim */}
        <div className="lg:w-1/2 ">
        <img src={bametalLogo} alt="About Us" class="lg:object-contain lg:object-center lg:h-screen lg:w-screen p-12 h-full w-full" />
        </div>
        {/* Metin İçeriği */}
        <div className="lg:w-1/2 lg:pl-10 flex items-center">
          <div className='mt-6'>
            <h2 className="text-4xl font-bold mb-4 md:text-start text-center text-reds">Hakkımızda</h2>
            <h3 className="text-2xl font-bold mb-4 md:text-start text-center text-primary">Değerli Müşterimiz,</h3>
            
            <p class="text-gray-900 mb-4 leading-relaxed font-semibold bg-yellow-200 p-4 rounded-lg">
            Firmamız, metal ihtiyaçlarınızı en doğru ve güvenilir şekilde karşılamak için burada.
             Sektördeki tecrübemiz ve uzman ekibimiz ile sizlere hızlı ve kaliteli hizmet sunmaktan mutluluk duyuyoruz. 
            </p>
            <p class="text-gray-900 mb-4 leading-relaxed font-semibold  p-4 rounded-lg">
            Her bir proje için en uygun metal çözümlerini sunmak için titizlikle çalışıyoruz. Geniş ürün yelpazemizde yer alan çeşitli metal türleri ile,
             müşterilerimizin farklı ihtiyaçlarını karşılayabilmekteyiz.
              Ayrıca, özel talepleriniz doğrultusunda özelleştirilmiş çözümler de sunmaktayız. 
            </p>
            <p class="text-gray-900 mb-4 leading-relaxed font-semibold  p-4 rounded-lg">
            İhtiyaçlarınızı en doğru şekilde belirlemek ve size en uygun ürünü temin etmek için uzman satış ekibimiz her zaman yanınızda. Sizlere zamanında teslimat yaparak,
             işlerinizin aksamadan ilerlemesini sağlamak en önemli önceliğimizdir. 
             Kaliteyi ve güveni bir arada sunarak, iş ortaklarımızın başarısına katkıda bulunmayı hedefliyoruz. Metal ihtiyaçlarınız için bize ulaşın, size en uygun çözümü birlikte bulalım. 
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsComp;