import React from 'react';
import HeroPage from '../components/HeroPage';
import bgimage from "../assets/bg.jpg";
import ContactForm from '../components/ContactForm';

const Contact = () => {
  return (
    <div className='bg-primary'>
        <HeroPage pageTitle="İLETİŞİM" backgroundImage={bgimage}/>
        <ContactForm/> 
    </div>
  );
}

export default Contact;