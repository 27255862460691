import React from 'react';
import ProductFeaturesType from './ProductFeaturesType';

const Product = ({ product }) => {
  // Özelliklerin değişkenlerde tanımlanması
  const { title, image, description, features ,usage_areas } = product;


  return (
    <section className='bg-white '>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
          <div className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:order-last lg:h-full">
              <img
                  alt=""
                  src={image}
                  class="absolute inset-0 h-full w-full object-cover p-0 lg:p-16"
              />
          </div>

            <div className="lg:py-24">
              <h2 className="text-3xl font-bold sm:text-4xl">{title}</h2>
              <p className="mt-4 text-gray-600">
                {description}
              </p>

              <h2 className="text-xl font-bold sm:text-2xl mt-8">Kullanım Alanları</h2>
              <p className="mt-4 text-gray-600">
                {usage_areas}
              </p> 
            </div>
          </div>
        </div>
       {/* Özellikler Tablosu */}
       <ProductFeaturesType features={features}/>
    </section>
    
  );
}

export default Product;