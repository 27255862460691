import React from 'react'
import HeroPage2 from '../components/HeroPage2'
import ProductsComp from '../components/ProductsComp'
import bgimage from "../assets/bg.jpg";
import ProductsData from '../data';
import bametalLogo from "../assets/bametal_logo.png";
import ProductComp2 from '../components/ProductComp2';



const Products = () => {
  return (
    <div className='bg-white'>
      <HeroPage2 pageTitle="ÜRÜNLER" backgroundImage={bgimage}/>
      <hr className=" border-2 border-reds rounded-full w-3/4 mx-auto mt-8" /> 
      <div class="flex justify-center">
        <img src={bametalLogo} alt="Logo" className="h-20 w-auto pb-6 mt-4 items-center" />
      </div>
      <hr className=" border-2 border-bls rounded-full w-3/4 mx-auto" /> 
      <ProductsComp products={ProductsData}/>
      <ProductComp2/>
    </div>
  )
}

export default Products