import React from 'react';
import { useParams } from 'react-router-dom';
import ProductsData from '../data';
import Product from '../components/Product';
import HeroPage2 from '../components/HeroPage2';
import bgimage from "../assets/bg.jpg";
import ProductList from '../components/ProductList';



const ProductFeatures = () => {
  const { id } = useParams();
  const product = ProductsData.find(product => product.id === parseInt(id));  

  return (
    <div className='bg-white'>
        <HeroPage2 backgroundImage={bgimage} pageTitle={product.title}/>
        <Product product={product}/>
        <ProductList/>
      {/* Diğer ürün detaylarını burada gösterebilirsiniz */}
    </div>
  );
}

export default ProductFeatures;