
import { FaArrowRight } from "react-icons/fa6";

const CatalogComp = () => {

     const openPdfInNewTab = () => {
        //URL
        const pdfUrl = process.env.PUBLIC_URL + '/BametalMetalSanayi.pdf';
    
        // PDF'i Yeni sekmede aç
        window.open(pdfUrl, '_blank');
      }; 


  return (
    <section className="text-gray-400 body-font bg-gray-900">
    <div className="container px-5 py-24 mx-auto">
      <div className="flex flex-wrap w-full mb-12 flex-col items-center text-center">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">Metal Sac Ürünleri</h1>
        <p className="lg:w-1/2 w-full leading-relaxed text-opacity-80">Değerli Müşterimiz,

        Metal sac imalatında uzmanlaşmış, yenilikçi ve dayanıklı ürünlerimizi sizin için sunuyoruz. Kataloğumuzu göz atarak detayları inceleyebilirsiniz. Kalite ve zarafeti bir arada bulabileceğiniz ürünlerimizi keşfetmek için sizleri bekliyoruz.

        Saygılarımızla,

        Bametal Sanayi.</p>
      </div>
     
      <button 
      className="flex mx-auto mt-16 text-white bg-reds border-0 py-2 px-8 focus:outline-none hover:bg-red-500 rounded text-lg justify-between items-center"
      onClick={openPdfInNewTab}
    >
      <span>Katalog Pdf</span>
      <FaArrowRight className="ml-2" />
    </button>
    </div>
  </section>
  )
}

export default CatalogComp