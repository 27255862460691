
import product1Image from "./assets/RULO_PAKET_SAC.jpg";
import product2Image from "./assets/product2.jpg";
import product3Image from "./assets/product3.jpg";
import product4Image from "./assets/product4.jpg";
import product5Image from "./assets/product5.jpg";
import product6Image from "./assets/product6.jpg";
import product7Image from "./assets/product7.jpg";
import product8Image from "./assets/product8.jpg";
import product9Image from "./assets/product9.jpg";

const ProductsData = [
    {
      id: 1,
      title: "SICAK HADDELENMİŞ RULO / PAKET SAC",
      image: product1Image,
      usage_areas:"Sıcak Haddellenmiş Saclar ağırlıklı olarak gemi inşa, enerji nakil hatları, boru ve profil üretimi, inşaat, otoyol bariyer ve levhaları, konteyner ve makina imalat sanayi gibi çeşitli alanlarda kullanılmaktadır.",
      date: "2024-03-02",
      description: "Sıcak haddeleme işlemiyle üretilen, genellikle çelik levhaların rulo halinde veya paket halinde satışa sunulan bir tür metal sacdır. İnşaat, otomotiv, gemi yapımı, makine imalatı gibi endüstrilerde yapısal bileşenlerin imalatında ve genel metal işleri için kullanılır.",
      features: [
        { name: "Kalınlık Aralığı", value: "1.50mm – 20mm" },
        { name: "Genişlik Aralığı", value: "1000/1200/1250/1500/2000 mm veya standart dışı genişlikler" },
        { name: "Standart/Kalite	", value: "EN Standartlarına göre DD11, DD12, DD13, S235JR, S355JR ASTM Stanadartlarına göre CS type B, DQSK, SQ" },
        { name: "Yüzey", value: "Asitlenmiş veya Asitlenmemiş" },
        { name: "Menşei", value: "Türkiye, Ukrayna, Rusya, Avrupa" },
        { name: "Formlar", value: "Rulo, Paket Sac, Bant" },
        { name: "Uygulamalar", value: "Gemi Sanayi, Otoyol, İnşaat, Gaz Konteynırları için Boru ve Profiller, Enerji Hatları vs" },
      ]
    },
    {
      id: 2,
      title: "SICAK HADDELENMİŞ LEVHA SAC",
      image: product2Image,
      usage_areas:"Sıcak haddelenmiş levha sac, inşaat, otomotiv, makine imalatı, boru imalatı, gemi yapımı, enerji sektörü ve tarım ekipmanları gibi birçok endüstride kullanılır. Yapı malzemelerinden otomotiv parçalarına kadar geniş bir uygulama alanına sahiptir.",
      
      description: "Sıcak haddeleme işlemiyle üretilen, genellikle çelik levhaların düz levha halinde satışa sunulan bir tür metal sacdır. Otomotiv parçaları, çelik yapılar, boru üretimi ve genel imalat endüstrisinde çeşitli yapısal ve dekoratif amaçlar için kullanılır.",
      features: [
        { name: "Kalınlık Aralığı", value: "5mm – 200mm" },
        { name: "Genişlik & Uzunluk Aralığı:", value: "1500-4000mm x 6000-12000mm" },
        { name: "Standart/Kalite", value: "En Standartlarına göre S235J2,S235J2G3 DIN Standarlarına göre St33,ST37-2,ST37-3U,St37-3N ASTM Standartlarına göre A283GrA/B/C/D,A284GrC/D,A36" },
        { name: "Menşei", value: "Türkiye, Ukrayna, Rusya, Avrupa" },
        { name: "Uygulamalar", value: "Makine Üretimi, Prefabrik Yapılar, Gemi Sanayi" },
      ]
    },
    {
      id: 3,
      title: "SOĞUK HADDELENMİŞ (DKP) RULO / PAKET SAC",
      image: product3Image,
      usage_areas:"Soğuk Haddelenmiş Rulo Saclar ağılıklı olarak, otomotiv,  mobilya, beyaz eşya, boru & profil, panel radyatör, inşaat & yapı malzemeleri, metal kalıplar başta olmak üzere endüstrinin birçok alanında kullanılmaktadır. ",
     
      description: "Soğuk haddeleme işlemiyle üretilen, genellikle çelik levhaların rulo halinde veya paket halinde satışa sunulan bir tür metal sacdır. Otomotiv endüstrisi, beyaz eşya imalatı, elektronik cihazlar, mobilya, ve ambalaj endüstrisi gibi birçok alanda tercih edilir. Hassas toleranslı ve düzgün yüzeyli olması nedeniyle genellikle iç bileşenlerin imalatında kullanılır.",
      features: [
        { name: "Kalınlık Aralığı", value: "0.20 mm – 2.50 mm" },
        { name: "Genişlik Aralığı", value: "Belirtilmemiş" },
        { name: "Standart/Kalite", value: "EN Standartlarına göre DC01, DC03, DC04, DC05, DC06 ASTM Standartlarına göre CD, DQ, DQSK" },
        { name: "Yüzey", value: "Mat veya Parlak Yüzey" },
        { name: "Menşei", value: "Türkiye, Çin, Avrupa" },
        { name: "Formlar", value: "Rulo, Paket Sac, Bant" },
        { name: "Uygulamalar", value: "Otomotiv,  mobilya, beyaz eşya, boru & profil, panel radyatör, inşaat & yapı malzemeleri, metal kalıplar vb." },
      ]
    },
    {
      "id": 4,
      "title": "HRP RULO / PAKET SAC (ASİTLENMİŞ YAĞLANMIŞ SAC)",
      "image": product4Image,
      "usage_areas": "HRP rulo veya paket sac, otomotiv, beyaz eşya, metal mobilya, endüstriyel ekipmanlar, metal ambalaj, inşaat ve elektrik/elektronik gibi çeşitli endüstrilerde kullanılır. Geniş uygulama alanı sunar.",
      "description": "Sıcak haddeleme sonrası asitle işlenerek yüzeyinin temizlenmesi ve yağlanmasıyla elde edilen bir tür metal sacdır. Otomotiv parçaları, inşaat malzemeleri, tesisat boruları, ve makine parçaları gibi uygulamalarda kullanılır. Paslanmaya karşı koruma sağlamak için kullanılır.",
      "features": [
        {"name": "Kalınlık (mm)", "value": "2.50, 2.50, 2.50, 3.00, 3.00, 3.00, 4.00, 4.00, 4.00, 6.00, 6.00, 6.00"},
        {"name": "En (mm)", "value": "1000, 1200, 1500, 1000, 1200, 1500, 1000, 1250, 1500, 1000, 1200, 1500"},
        {"name": "Boy (mm)", "value": "2000, 2500, 2500, 2000, 2500, 2500, 2500, 2500, 2500, 2000, 2500, 2500"},
        {"name": "Ağırlık (kg/m)", "value": "40, 60, 75, 48, 72, 90, 96, 100, 120, 96, 144, 180"}
      ]
    },
    {
      id: 5,
      title: "GALVANİZLİ RULO / PAKET SAC",
      image: product5Image,
      usage_areas:"Otomotiv, beyaz eşya, elektrik - elektronik, küçük ev aletleri, ıstıma ve soğutma, havalandırma, inşaat (PVC destek sacı, açık yapı profili) vb alanlarda Galvanizli Rulo Sac ve Galvanizli Levha Sac kullanılmaktadır.",
      
      description: "Çelik levhaların sıcak daldırma galvanizasyon işlemiyle çinko kaplanmasıyla elde edilen bir tür metal sacdır. Çatı kaplamaları, duvar panelleri, çitler, su boruları, elektrik kablo kanalları ve beyaz eşya gibi birçok endüstriyel ve inşaat uygulamasında kullanılır. Paslanmaya karşı dayanıklılık sağlamak için çinko kaplanır.",
      features: [
        {"name": "Kalınlık (mm)", "value": "0.25,0.30,0.35,0.40,0.45,0.50,0.55,0.60,0.70,0.80,0.90,1.00,1.20,1.50,2.00,2.50,3.00,4.00"},
        {"name": "10002000 (kg)", "value": "4.00,4.80,5.60,6.40,7.20,8.00,8.80,9.60,11.20,12.80,14.40,16.00,19.20,24.00,32.00,40.00,48.00,64.00"},
        {"name": "12002400 (kg)", "value": "5.76,6.91,8.06,9.22,10.37,11.52,12.67,13.82,16.13,18.43,20.74,23.04,27.65,34.56,46.08,57.60,69.12,92.16"},
        {"name": "12502500 (kg)", "value": "6.25,7.50,8.75,10.00,11.25,12.50,13.75,15.00,17.50,20.00,22.50,25.00,30.00,37.50,50.00,62.50,75.00,100.00"},
        {"name": "15003000 (kg)", "value": "9.00,10.80,12.60,14.40,16.20,18.00,19.80,21.60,25.20,28.80,32.40,36.00,43.20,54.00,72.00,90.00,108.00,144.00"}
      ]
    },
    {
      id: 6,
      title: "BOYALI SAC",
      image: product6Image,
      usage_areas:"Bizim Bametal olarak, boyalı sacın inşaat, otomotiv, beyaz eşya, metal mobilya, ambalaj, elektrik/elektronik ve dekorasyon gibi çeşitli endüstrilerde kullanıldığını görmekteyiz. Boyalı sac, estetik ve dayanıklı bir malzeme olarak tercih edilir ve geniş renk seçenekleri sunar.",
      date: "2024-03-03",
      description: "Metal levhaların üzerine boya uygulanarak renklendirilmesiyle elde edilen bir tür metal sacdır. İnşaat endüstrisi, reklam panoları, ev eşyaları, konteynerler, ve otomotiv parçaları gibi dekoratif uygulamalarda Estetik amaçlarla tercih edilir ve genellikle iç ve dış dekorasyon uygulamalarında kullanılır. ",
      features: [
        { "name": "Kalınlık (mm)", value: "0.5,0.6,0.7,0.8,0.9,1.0,1.2,1.5,2.0,2.5,3.0" },
        {"name": "Ağırlık (kg/m)", "value": "3.9,4.7,5.5,6.3,7.0,7.8,9.4,11.8,15.7,19.6,23.5"},
      ]
    },
    {
      id: 7,
      title: "BAKLAVALI / GÖZYAŞI DESENLİ SAC",
      image: product7Image,
      usage_areas:"Damper ve treyler, yangın merdiveni imalatı, yükleme rampaları, gemi inşa sanayi (güverte başta olmak üzere yürüme yolları vb), inşaat sektörü (endüstriyel çelik yapılar, merdiven imalatı, yangın merdiveni, çelik basamaklar vb), makina imalat sanayi gibi birçok alanda baklavalı (desenli) saclar tercih edilir.",
      date: "2024-03-03",
      description: "Metal levhaların yüzeyine özel bir desenin işlenmesiyle elde edilen bir tür metal sacdır. Çatı kaplamaları, duvar panelleri, zemin döşemeleri, dekoratif parçalar ve çeşitli ambalaj uygulamalarında mekanik dayanıklılığı artırmak ve kaymaz bir yüzey sağlamak için kullanılır.",
      features: [
        {"name": "Kalınlık(mm)", "value": "0.5 - 3.0"},
        {"name": "En (mm)", "value": "1000 - 1500"},
        {"name": "Boy (mm)", "value": "2000 - 6000"},
        {"name": "Ağırlık (kg/m)", "value": "3.9 - 23.5"},
        { "name": "Standart/Kalite", "value": "S235JR" },
        { "name": "Yüzey", "value": "Galvanizli veya Boyalı" },
        { "name": "Menşei", "value": "Türkiye" },
        { "name": "Formlar", "value": "Rulo veya Levha" },
        { "name": "Uygulamalar", "value": "İnşaat, Otomotiv, Makine Sanayi, Dekorasyon" },
        
      ]
    },
    {
      id: 8,
      title: "TRAPEZ / OLUKLU SAC",
      image: product8Image,
      usage_areas:"Bametal olarak, Trapez/Oluklu Sac'ın inşaat sektöründe çatı ve duvar kaplamaları, tarım yapıları, endüstriyel tesisler, güvenlik kaplamaları ve reklam panoları gibi birçok alanda kullanıldığını görmekteyiz. Bu sac türü, dayanıklı ve ekonomik bir yapı malzemesi olarak tercih edilir.",
      date: "2024-03-03",
      description: "Metal levhaların üzerine oluklar veya trapez şekillerinin preslenmesiyle elde edilen bir tür metal sacdır. Çatı kaplamaları, duvar panelleri, çitler, depo yapıları, endüstriyel tesisler ve tarım binaları gibi büyük yapıların kaplamalarında kullanılır ve suyun tahliyesi ve mukavemet için tercih edilir.",
      features: [
        {"name": "Kalınlık(mm)", "value": "0.4 - 0.8"},
        {"name": "En (mm)", "value": "1000-1250"},
        {"name": "Boy (mm)", "value": "2000-6000"},
        {"name": "Ağırlık (kg/m)", "value": "3.14 - 6.28"},
        { "name": "Standart/Kalite", "value": "DX51D+Z" },
        { "name": "Yüzey", "value": "Galvanizli" },
        { "name": "Menşei", "value": "Türkiye" },
        { "name": "Formlar", "value": "Rulo veya Levha" },
        { "name": "Uygulamalar", "value": "İnşaat, Çatı Kaplama, Duvar Kaplama, Depo, Tarım" },
        
      ]
    },
    {
      id: 9,
      title: "GENİŞLETİLMİŞ / DELİKLİ SAC",
      image: product9Image,
      usage_areas:"Bametal olarak, Genişletilmiş/Delikli Sac'ın endüstriyel zeminler, güvenlik panelleri, filtreler, dekoratif uygulamalar, merdivenler ve rıhtımlar gibi farklı alanlarda kullanıldığını görüyoruz. Bu sac türü, dayanıklı ve çok yönlü bir malzeme olarak tercih edilir.",
      date: "2024-03-03",
      description: "Metal levhaların özel bir şekilde işlenerek genişletilmesi veya delikler açılmasıyla elde edilen bir tür metal sacdır. Filtreler, havalandırma panelleri, güvenlik kapıları, dekoratif paneller ve koruma kafesleri gibi uygulamalarda kullanılır.",
      features: [
        {"name": "Kalınlık(mm)", "value": "0.5 - 6.0"},
        {"name": "En (mm)", "value": "1000-2000"},
        {"name": "Boy (mm)", "value": "2000-6000"},
        {"name": "Ağırlık (kg/m)", "value": "Değişken"},
        { "name": "Standart/Kalite", "value": "S235JR" },
        { "name": "Yüzey", "value": "Galvanizli veya Boyalı" },
        { "name": "Menşei", "value": "Türkiye" },
        { "name": "Formlar", "value": "Levha" },
        { "name": "Uygulamalar", "value": "Filtreler, Merdiven Basamakları, Zemin Kaplamaları, Güvenlik Amaçlı Kafesler, Dekoratif Paneller" },
      ]
    },
  ];


  export default ProductsData;